import { render, staticRenderFns } from "./onlineDepositStatus.vue?vue&type=template&id=07879dba&scoped=true"
import script from "./onlineDepositStatus.vue?vue&type=script&lang=js"
export * from "./onlineDepositStatus.vue?vue&type=script&lang=js"
import style0 from "./onlineDepositStatus.vue?vue&type=style&index=0&id=07879dba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07879dba",
  null
  
)

export default component.exports